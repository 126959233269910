// Define variable defaults
//$body-bg: #000;
//$body-color: #111;
$table-cell-vertical-align: middle;
$primary: #4F2B76;

// Then import Bootstrap an BootstrapVue SCSS files (order is important)
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
body{background-color: #efefef;}
.container {
        border-radius: 5px;
	    width: 400px;
        box-shadow: 10px 10px 8px #e0e0e0;
        background-color: #fff;
        margin-top: 100px;
        padding-top: 15px;
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 15px;
        margin-right: auto;
        margin-left: auto;
        border: 1px solid darkgrey;
}
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
	border-radius: 15px;
  	box-shadow: 10px 10px 8px #e0e0e0;
	background-color: #fff;
	margin-top: 100px;
	width: 30%;
	padding-top: 15px;
	padding-right: 5px;
	padding-left: 5px;
	padding-bottom: 15px;
	margin-right: auto;
	margin-left: auto;
	border: 1px solid darkgrey;
}
h5 { 
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 20px; 
	color: darkgrey;
   }
.h5,
h5 {
	font-size: 1rem;
	text-align: center
}
h2 {
        font-size: 1.2rem;
       }
.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	background-color: #fff;
}
.btn:hover {
	color: #0f0099;
	text-decoration: none 
}
.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	padding: .3rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #c0c0c0;
	border-radius: .25rem
}

.col-xl-2 {
	border: 1px solid #c0c0c0; 
	border-radius: 15px;
	text-align: center;
}
.btn {
	padding: .15rem .5rem;
	margin: .3rem .3rem .3rem .3rem;
}
.statbox {
        width:100%;
	background:#f5f5f5;
        border-radius: 5px;
        text-align: right;
        }
.statbox-text {
        text-align: right;
        width:100%;
        font-size: 1rem;
        }
.statbox-p {
    color:#9fa8b9
}
